body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  transition: background-color 0.6s ease;
}

.container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
}
.container_logo {
  max-width: 640px;
  margin: 0 auto;
  padding:20px 0px; 
}

.logo{
  max-width: 200px;
}

.headermain{
  background-color: #0089D2;
}
h2 {
  text-align: center;
  color: #333;
}

form {
  display: flex;
  flex-direction: column;
}

label {
  margin-top: 10px; 
  color: #2F519A;
  font-size:12px;
}

input, select {
  margin-top: 5px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  border-radius: 20px;
  padding: 10px 23px;
}
.contentedor_botones{
  margin-top:20px;
}
.MuiButton-containedSuccess{
  display: block!important;
  width: 100%!important;
  background-color: #84C460!important;
}
button {
  margin-top: 20px;
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
 
button:hover {
  background-color: #0056b3;
}
.title_wh{
  color:#fff;
  text-align: left;
  max-width: 640px;
  padding-top:10px;padding-bottom: 10px;
  margin:0 auto;
}
label {
  display: block;
  margin-top: 10px;
}

input[type="checkbox"] {
  margin-right: 10px;
}
 
.Mui-selected,.Mui-selected:hover {
 
  background-color: #f9006f!important;
}
 
.css-15rfbsb-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed {
  color: #85156e!important;
}
.css-15rfbsb-MuiSvgIcon-root-MuiStepIcon-root.Mui-active {
  color: #fff !important;
  border: 1px solid #85156e;
  border-radius: 23px;
}
.css-15rfbsb-MuiSvgIcon-root-MuiStepIcon-root.Mui-active  .css-117w1su-MuiStepIcon-text {
  fill: #85156e;
  font-size: 0.75rem;font-weight: bold;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
}
.css-117w1su-MuiStepIcon-text { 
  font-weight: bold; 
}
.MuiStepper-root.MuiStepper-horizontal{
  max-width: 250px;
  margin: 0 auto;
}
.colortxt{
  transition: all ease-in-out 0.3s;
  transition-delay: 200ms;
  font-size: 20px;
}
.color0{
  color:#702768;
}
.color1{
  color:#E43270;
}
.color2{
  color:#702768;
}
.calugaprecio{
  color:#fff;
  background-color: #E43270;
  padding:5px;
  border-radius: 20px;;
  text-align: center;
  margin-top:20px;
}
.calugaprecio p{
  font-size:30px;
  margin: 10px auto;
  margin-top:0px;
}
.calugaprecio h3{ 
  font-size: 12px;
  font-weight: 300;
  margin-bottom:0px;
}
.aviso{
  font-size:12px;
}
.rosadotxt{
  color:#E43270;
}
.leftp{
  padding-left:2px;
}
.leftpb2{
  padding-left: 2px;
  font-size: 20px;
  color: #702768;
  padding-top: 9px;
  padding-bottom: 5px;}
@media (max-width:600px) {

  .logo{
   margin-left: 20px;
  }
  .title_wh{
    margin-left: 20px;
  }
  .container{
    margin:0px 10px;
  }
  }